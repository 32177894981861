import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { color, space } from "../../theme"

import Text from "../../components/text"
import Img from "../../components/img"
import Anchor from "../../components/anchor"

export type BankingProps = {
  title: string
  subtitle: string
  copy: string
  image: ImageType
}

type Props = {
  data: BankingProps
}

const Wrapper = styled.div`
  display: grid;
  position: relative;
`

const LimitWiderPart = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 0 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const IImg = styled(Img)`
  border-bottom: 1px solid ${color("black")};

  ${up("desktop")} {
    border-bottom: none;
  }
`

const BankingLiteContent = styled.div`
  display: grid;
  grid-gap: 6rem;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  grid-gap: ${space("small")};
  grid-template-columns: 1fr;
  ${Text} {
    text-align: center;
  }
  ${up("desktop")} {
    padding: 4rem 3rem;
    align-items: flex-start;
    grid-gap: ${space("xlarge")};
    grid-template-columns: auto 1fr;
    ${Text} {
      text-align: left;
    }
  }
`
const BankingLiteCopy = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 2rem;
`

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${color("blue")};
  z-index: -1;
`

const BankingLiteContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${up("desktop")} {
    grid-gap: 2rem;
    grid-template-columns: 255px auto 1fr;
  }
`

const BankingLite: React.FC<Props> = ({
  data: { image, title, subtitle, copy },
}) => {
  return (
    <Wrapper>
      <BG />
      <LimitWiderPart>
        <Anchor id="banking" top="-80px" />
        <BankingLiteContainer>
          <IImg
            src={image.src}
            alt={image.alt}
            mw={"90%"}
            mh={355}
            tw={"auto"}
            th={500}
            dw={255}
            dh={320}
            fit="cover"
          />
          <BankingLiteContent>
            <Text color="white" type="h4">
              {title}
            </Text>
            <BankingLiteCopy>
              <Text color="white">{subtitle}</Text>
              <Text color="white">{copy}</Text>
            </BankingLiteCopy>
          </BankingLiteContent>
        </BankingLiteContainer>
      </LimitWiderPart>
    </Wrapper>
  )
}

export default BankingLite
