import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { color } from "../theme"

const L = styled.li`
  position: relative;
  padding-left: 1.5rem;
  &:before {
    position: absolute;
    content: "";
    top: 7px;
    left: 0;
    width: 8px;
    height: 8px;
  }
`

const U = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  ${L}:nth-child(n+1):before {
    background-color: ${color("green")};
  }
  ${L}:nth-child(n+2):before {
    background-color: ${color("blue")};
  }
  ${L}:nth-child(n+3):before {
    background-color: ${color("red")};
  }
  ${L}:nth-child(n+4):before {
    background-color: ${color("purple")};
  }
  ${up("desktop")} {
    margin: 0;
  }
`

export const List: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <U>
      {items.map((item, i) => (
        <L key={`list_item_${i}`}>{item}</L>
      ))}
    </U>
  )
}
export default List
