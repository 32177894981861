import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../../../components/text"
import { Link } from "../../../components/link"
import List from "../../../components/list"
import Anchor from "../../../components/anchor"
import Faces from "../../../components/whatweoffer/faces"
import { Cube, CubeProps, Circle } from "../../../components/whatweoffer/grid"
import { FlexibleProps } from "./"

const Wrapper = styled.div`
  position: relative;
  display: grid;
  min-height: 800px;
`

const LimitWiderPart = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding: 0 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
    grid-template-rows: min-content min-content;
  }
`

const FlexibleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 300px;
  grid-gap: 5rem;
`
const FlexibleImgContainer = styled.div`
  display: grid;
  justify-items: flex-start;
  align-items: flex-start;
  padding: 5rem 0;
`
const FlexibleContent = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 2rem;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 5rem 0;
  > ${Text} {
    max-width: 620px;
  }
`
const FlexibleGrid = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(32, minmax(18px, auto));
  grid-template-rows: repeat(50, minmax(auto, 18px));
  ${up("tablet")} {
    grid-template-columns: repeat(32, minmax(24px, auto));
    grid-template-rows: repeat(50, minmax(auto, 24px));
  }
  ${up("desktop")} {
    grid-template-columns: repeat(10, 40px);
    grid-template-rows: repeat(20, 40px);
  }
  justify-content: flex-start;
`
const PurpleCube = styled(Cube)<CubeProps>`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 1rem;
  justify-items: flex-start;
  padding: 2rem;
`
const pipe3: CubeProps[] = [
  { mobile: { x: 5, y: 4, w: 6, h: 1 }, bg: "darkgreen", topleft: true },
  { mobile: { x: 5, y: 5, w: 1, h: 16 }, bg: "darkgreen" },
]
const circlePipe: CubeProps[] = [
  {
    mobile: { x: 5, y: 6, w: 1, h: 1 },
    bg: "yellow",
    z: 1,
  },
]

const Flexible: React.FC<{ data: FlexibleProps }> = ({
  data: {
    title,
    list,
    copy,
    block,
    block: { link },
  },
}) => {
  return (
    <Wrapper>
      <LimitWiderPart>
        <Anchor id="flexible" top="-80px" />
        <FlexibleContainer>
          <FlexibleImgContainer>
            <Faces />
          </FlexibleImgContainer>
          <FlexibleContent>
            <Text type="h4">{title}</Text>
            <List items={list} />
            <Text>{copy}</Text>
          </FlexibleContent>
        </FlexibleContainer>
      </LimitWiderPart>
      <FlexibleGrid>
        {circlePipe.map((pipe, i) => (
          <Circle key={`circle3_${i}`} {...pipe} />
        ))}
        {pipe3.map((pipe, i) => (
          <Cube key={`cube3_${i}`} {...pipe} />
        ))}
        <PurpleCube mobile={{ x: 2, y: 8, w: 7, h: 8 }} bg="purple">
          <Text color="yellow">{block.title}</Text>
          <Text color="white">{block.copy}</Text>
          <Link to={link.to}>{link.copy}</Link>
        </PurpleCube>
      </FlexibleGrid>
    </Wrapper>
  )
}

export default Flexible
