import * as React from "react"

import useBreakpoints from "../../../utils/use-breakpoint"

import DesktopFlexible from "./desktop"
import TabletFlexible from "./tablet"

export type FlexibleProps = {
  title: string
  list: string[]
  copy: string
  block: { title: string; copy: string; link: LinkType }
}

type Props = {
  data: FlexibleProps
}

const Flexible: React.FC<Props> = ({ data }) => {
  const breakpoint = useBreakpoints({
    defaultValue: "unknown",
    config: { mobile: 0, tablet: 768, desktop: 1145 },
  })
  switch (breakpoint) {
    case "unknown":
      return null
    case "mobile":
    case "tablet":
      return <TabletFlexible data={data} />
    case "desktop":
    default:
      return <DesktopFlexible data={data} />
  }
}

export default Flexible
