import * as React from "react"

import useBreakpoints from "../../../utils/use-breakpoint"

import DesktopIntro from "./desktop"
import TabletIntro from "./tablet"

export type IntroProps = {
  title1: string
  copy1: string
  title2: string
  subtitle21: string
  copy21: string
  subtitle22: string
  copy22: string
  subtitle23: string
  copy23: string
  subtitle24: string
  copy24: string
  subtitle25: string
  copy25: string
  subtitle26: string
  copy26: string
  link26: LinkType
  title3: string
  subtitle31: string
  copy31: string
  subtitle32: string
  copy32: string
}

type Props = {
  data: IntroProps
}

const Intro: React.FC<Props> = ({ data }) => {
  const breakpoint = useBreakpoints({
    defaultValue: "unknown",
    config: { mobile: 0, tablet: 768, desktop: 1145 },
  })
  switch (breakpoint) {
    case "unknown":
      return null
    case "mobile":
    case "tablet":
      return <TabletIntro data={data} />
    case "desktop":
    default:
      return <DesktopIntro data={data} />
  }
}

export default Intro
