import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { color } from "../../theme"

import Text from "../../components/text"
import List from "../../components/list"
import Img from "../../components/img"
import Anchor from "../../components/anchor"

export type ConsoleProps = {
  title: string
  copy: string
  list: string[]
  image: ImageType
}

type Props = {
  data: ConsoleProps
}

const LimitWiderPart = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 4rem 2rem;
  border-top: 1px solid ${color("lightgrey")};
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const IImg = styled(Img)``
const ConsoleContent = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 2rem;
  align-content: flex-start;
  justify-content: flex-start;
  ${Text} {
    text-align: center;
  }

  ${up("desktop")} {
    ${Text} {
      text-align: left;
    }
  }
`

const ConsoleContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  ${IImg} {
    order: 1;
  }
  ${ConsoleContent} {
    order: 2;
  }
  ${up("desktop")} {
    grid-template-columns: 1fr auto;
    ${IImg} {
      order: 2;
    }
    ${ConsoleContent} {
      order: 1;
    }
  }
`

const Console: React.FC<Props> = ({ data: { title, copy, list, image } }) => {
  return (
    <LimitWiderPart>
      <Anchor id="console" top="-80px" />
      <ConsoleContainer>
        <ConsoleContent>
          <Text type="h4">{title}</Text>
          <Text>{copy}</Text>
          <List items={list} />
        </ConsoleContent>
        <IImg
          src={image.src}
          alt={image.alt}
          mw="100%"
          mh="auto"
          dw={834}
          dh={475}
          fit="contain"
        />
      </ConsoleContainer>
    </LimitWiderPart>
  )
}

export default Console
