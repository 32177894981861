import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { color } from "../../theme"

import Anchor from "../../components/anchor"
import Text from "../../components/text"
import Img from "../../components/img"

type BlockType = {
  title: string
  copy: string
  image: ImageType
}

export type RewardProps = {
  title: string
  block1: BlockType
  block2: BlockType
  block3: BlockType
}

type Props = { data: RewardProps }

const LimitWiderPart = styled.div`
  position: relative;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr;
  margin: 0 auto;
  border-bottom: 1px solid ${color("lightgrey")};
  padding: 6rem 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
    grid-template-rows: 1fr auto;
  }
`

const RewardContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
const RewardItem = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: 2rem;
  ${Text} {
    text-align: center;
  }

  ${up("desktop")} {
    ${Text} {
      text-align: left;
    }
  }
`

const Reward: React.FC<Props> = ({
  data: { title, block1, block2, block3 },
}) => {
  return (
    <LimitWiderPart>
      <Anchor id="reward" top="-80px" />
      <Text type="h4">{title}</Text>
      <RewardContainer>
        <RewardItem>
          <Img
            src={block1.image.src}
            alt={block1.image.alt}
            mw={140}
            mh={120}
            fit="contain"
          />
          <Text color="blue">{block1.title}</Text>
          <Text>{block1.copy}</Text>
        </RewardItem>
        <RewardItem>
          <Img
            src={block2.image.src}
            alt={block2.image.alt}
            mw={140}
            mh={120}
            fit="contain"
          />
          <Text color="blue">{block2.title}</Text>
          <Text>{block2.copy}</Text>
        </RewardItem>
        <RewardItem>
          <Img
            src={block3.image.src}
            alt={block3.image.alt}
            mw={140}
            mh={120}
            fit="contain"
          />
          <Text color="blue">{block3.title}</Text>
          <Text>{block3.copy}</Text>
        </RewardItem>
      </RewardContainer>
    </LimitWiderPart>
  )
}

export default Reward
