import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { color, space } from "../../theme"

import Text from "../../components/text"
import { Link } from "../../components/link"
import List from "../../components/list"
import Anchor from "../../components/anchor"
import Animation from "../../components/animation"

export type EscrowProps = {
  title: string
  animation: string
  list: string[]
  copy: string
  link: LinkType
}

type Props = {
  data: EscrowProps
}

const LimitWiderPart = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: ${space("large")} ${space("normal")};
  padding-bottom: ${space("normal")};
  border-bottom: 1px solid ${color("lightgrey")};
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const EscrowContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: ${space("normal")};
  justify-items: flex-start;
  ${Text} {
    text-align: center;
  }
  ${up("desktop")} {
    ${Text} {
      text-align: left;
    }
  }
`

const AnimationContainer = styled(Animation)`
  position: relative;
  width: 100%;
  height: auto;
  ${up("desktop")} {
    width: 590px;
    height: 480px;
  }
`
const EscrowContainer = styled.div`
  display: grid;
  grid-gap: ${space("normal")};
  padding: 0;
  grid-template-columns: 1fr;
  > ${Text} {
    order: 2;
    text-align: center;
  }
  > ${AnimationContainer} {
    order: 1;
  }
  > ${EscrowContent} {
    order: 3;
  }
  ${up("desktop")} {
    grid-gap: ${space("large")};
    padding: ${space("large")} 0;
    grid-template-columns: auto auto 1fr;
    > ${Text} {
      order: 1;
      text-align: left;
    }
    > ${AnimationContainer} {
      order: 2;
    }
  }
`

const Escrow: React.FC<Props> = ({
  data: { title, animation, list, copy, link },
}) => {
  return (
    <LimitWiderPart>
      <Anchor id="escrow" />
      <EscrowContainer>
        <Text type="h4">{title}</Text>
        <AnimationContainer path={animation} />
        <EscrowContent>
          <List items={list} />
          <Text>{copy}</Text>
          <Link to={link.to}>{link.copy}</Link>
        </EscrowContent>
      </EscrowContainer>
    </LimitWiderPart>
  )
}

export default Escrow
