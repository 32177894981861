import * as React from "react"
import styled from "styled-components"

import { color, space } from "../../../theme"
import { Cube, Circle } from "../../../components/whatweoffer/grid"
import Text from "../../../components/text"
import { Link } from "../../../components/link"
import Anchor from "../../../components/anchor"
import { IntroProps } from "."

const CustomGrid = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(19, calc(100vw / 19));
  grid-template-rows:
    repeat(5, calc(100vw / 19)) auto repeat(5, calc(100vw / 19))
    auto repeat(4, calc(100vw / 19));
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  background-color: transparent;
`
const PinkGrid = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(19, calc(100vw / 19));
  grid-template-rows: repeat(10, calc(100vw / 19));
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  background-color: ${color("rose")};
  border-bottom: 1px solid #d96b85;
`

const FirstBlock = styled.div`
  grid-row: 4/7;
  grid-column: 4/19;
  display: grid;
  grid-gap: 2rem;
`
const SecondBlock = styled.div`
  grid-row: 10/13;
  grid-column: 2/17;
  display: grid;
  grid-gap: 2rem;
  justify-items: flex-start;
`
const ThridBlock = styled.div`
  display: grid;
  grid-gap: 2rem;
  justify-items: flex-start;
  background-color: ${color("rose")};
  padding: ${space("large")} ${space("normal")};
`

const Hero = styled.div`
  padding: ${space("normal")};
  display: grid;
  margin: 0 ${space("normal")};
  grid-gap: ${space("normal")};
  border-bottom: 1px solid ${color("lightgrey")};
`

const Intro: React.FC<{ data: IntroProps }> = ({
  data: {
    title1,
    copy1,
    title2,
    subtitle21,
    copy21,
    subtitle22,
    copy22,
    subtitle23,
    copy23,
    subtitle24,
    copy24,
    subtitle25,
    copy25,
    subtitle26,
    copy26,
    link26,
    title3,
    subtitle31,
    copy31,
    subtitle32,
    copy32,
  },
}) => {
  return (
    <>
      <Hero>
        <Text type="headline" align="center">
          {title1}
        </Text>
        <Text align="center" color="grey">
          {copy1}
        </Text>
      </Hero>
      <CustomGrid>
        <Cube mobile={{ x: 10, y: 1, w: 1, h: 1 }} bg="lightblue" />
        <Cube
          mobile={{ x: 2, y: 2, w: 9, h: 1 }}
          bg="lightblue"
          topleft
          bottomright
        />
        <Circle mobile={{ x: 2, y: 5, w: 1, h: 1 }} bg="yellow" z={1} />
        <FirstBlock>
          <Anchor id={"flexibility"} top="0px" />
          <Text type="h4">{title2}</Text>
          <Text color="blue">{subtitle21}</Text>
          <Text>{copy21}</Text>
          <Text color="darkgreen">{subtitle22}</Text>
          <Text>{copy22}</Text>
        </FirstBlock>
        <Cube mobile={{ x: 2, y: 3, w: 1, h: 6 }} bg="lightblue" bottomleft />
        <Cube mobile={{ x: 3, y: 8, w: 16, h: 1 }} bg="lightblue" topright />
        <SecondBlock>
          <Text color="darkgreen">{subtitle23}</Text>
          <Text>{copy23}</Text>
          <Text color="blue">{subtitle24}</Text>
          <Text>{copy24}</Text>
          <Text color="red">{subtitle25}</Text>
          <Text>{copy25}</Text>
          <Text color="darkgreen">{subtitle26}</Text>
          <Text>{copy26}</Text>
          <Link to={link26.to}>{link26.copy}</Link>
        </SecondBlock>
        <Cube mobile={{ x: 18, y: 9, w: 1, h: 5 }} bg="lightblue" />
        <Circle mobile={{ x: 18, y: 11, w: 1, h: 1 }} bg="yellow" z={1} />
        <Cube
          mobile={{ x: 10, y: 14, w: 9, h: 1 }}
          bg="lightblue"
          topleft
          bottomright
        />
        <Cube mobile={{ x: 10, y: 15, w: 1, h: 2 }} bg="lightblue" />
      </CustomGrid>
      <PinkGrid>
        <Cube mobile={{ x: 10, y: 1, w: 1, h: 3 }} bg="lightblue" />
        <Cube mobile={{ x: 6, y: 4, w: 9, h: 1 }} bg="blue" topleft topright />
        <Cube mobile={{ x: 6, y: 5, w: 1, h: 6 }} bg="blue" />
        <Cube mobile={{ x: 14, y: 5, w: 1, h: 6 }} bg="blue" />
        <Circle mobile={{ x: 6, y: 7, w: 1, h: 1 }} bg="yellow" z={1} />
        <Circle mobile={{ x: 14, y: 7, w: 1, h: 1 }} bg="yellow" z={1} />
      </PinkGrid>
      <ThridBlock>
        <Anchor id={"split"} top="0px" />
        <Text type="h4">{title3}</Text>
        <div>
          <Text color="blue">{subtitle31}</Text>
          <Text>{copy31}</Text>
        </div>
        <div>
          <Text color="blue">{subtitle32}</Text>
          <Text>{copy32}</Text>
        </div>
      </ThridBlock>
    </>
  )
}

export default Intro
