import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { space, color } from "../../../theme"

import Text from "../../../components/text"
import Img from "../../../components/img"
import { Link } from "../../../components/link"
import List from "../../../components/list"
import Anchor from "../../../components/anchor"
import { FlexibleProps } from "./"

const Wrapper = styled.div`
  position: relative;
  display: grid;
`

const LimitWiderPart = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding: ${space("normal")};
`

const FlexibleContent = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 2rem;
  align-content: flex-start;
  justify-content: center;
  padding: ${space("normal")} 0;
  ${up("tablet")} {
    padding: ${space("normal")};
  }
  > ${Text} {
    max-width: 620px;
  }
`

const Purple = styled.div`
  display: grid;
  grid-template-areas: auto 1fr auto;
  justify-items: flex-start;
  grid-gap: ${space("small")};
  padding: ${space("normal")};
  background-color: ${color("purple")};
`

const Flexible: React.FC<{ data: FlexibleProps }> = ({
  data: {
    title,
    list,
    copy,
    block,
    block: { link },
  },
}) => {
  return (
    <Wrapper>
      <LimitWiderPart>
        <Anchor id="flexible" top="-80px" />
        <Img
          src="/what-we-offer/faces.svg"
          alt="faces"
          mw="90%"
          mh="auto"
          tw="auto"
          th="480px"
          fit="contain"
        />
        <FlexibleContent>
          <Text align="center" type="h4">
            {title}
          </Text>
          <List items={list} />
          <Text align="center">{copy}</Text>
        </FlexibleContent>
        <Purple>
          <Text color="yellow">{block.title}</Text>
          <Text color="white">{block.copy}</Text>
          <Link to={link.to}>{link.copy}</Link>
        </Purple>
      </LimitWiderPart>
    </Wrapper>
  )
}

export default Flexible
