import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../text"
import { Link } from "../link"
import Icon, { IconProps } from "../icon"
import { getBg } from "../../theme"

export const SideGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(50, minmax(auto, 18px));
  ${up("tablet")} {
    grid-template-rows: repeat(50, minmax(auto, 24px));
  }
  ${up("desktop")} {
    grid-template-rows: repeat(50, minmax(auto, 40px));
  }
`
export const Grid = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(25, calc(100vw / 25));
  grid-template-rows: repeat(116, calc(100vw / 25));
  ${up("tablet")} {
    position: relative;
    grid-template-columns: repeat(25, calc(100vw / 25));
    grid-template-rows: repeat(66, calc(100vw / 25));
  }
  ${up("desktop")} {
    position: absolute;
    grid-template-columns: repeat(32, minmax(40px, auto));
    grid-template-rows: repeat(17, minmax(auto, 40px));
  }
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  background-color: transparent;
`
export type CubeProps = {
  mobile?: {
    x: number
    y: number
    w: number
    h: number
  }
  tablet?: {
    x: number
    y: number
    w: number
    h: number
  }
  desktop?: {
    x: number
    y: number
    w: number
    h: number
  }
  topleft?: boolean
  topright?: boolean
  bottomleft?: boolean
  bottomright?: boolean
  bg?: ColorKeys
  z?: number
}

export type SuperCubeProps = CubeProps & IconProps

const mixin = css<CubeProps>`
  grid-column-start: ${p => (p.mobile ? p.mobile.x : 1)};
  grid-column-end: ${p => (p.mobile ? p.mobile.x + p.mobile.w : 1)};

  grid-row-start: ${p => (p.mobile ? p.mobile.y : 1)};
  grid-row-end: ${p => (p.mobile ? p.mobile.y + p.mobile.h : 1)};
  border-top-left-radius: ${p => (p.topleft ? 30 : 0)}px;
  border-top-right-radius: ${p => (p.topright ? 30 : 0)}px;
  border-bottom-right-radius: ${p => (p.bottomright ? 30 : 0)}px;
  border-bottom-left-radius: ${p => (p.bottomleft ? 30 : 0)}px;
  ${up("tablet")} {
    grid-column-start: ${p => (p.tablet ? p.tablet.x : null)};
    grid-column-end: ${p =>
      (p.tablet ? p.tablet.x : 0) + (p.tablet ? p.tablet.w : 0)};

    grid-row-start: ${p => (p.tablet ? p.tablet.y : null)};
    grid-row-end: ${p =>
      (p.tablet ? p.tablet.y : 0) + (p.tablet ? p.tablet.h : 0)};
  }
  ${up("desktop")} {
    grid-column-start: ${p => (p.desktop ? p.desktop.x : null)};
    grid-column-end: ${p =>
      (p.desktop ? p.desktop.x : 0) + (p.desktop ? p.desktop.w : 0)};

    grid-row-start: ${p => (p.desktop ? p.desktop.y : null)};
    grid-row-end: ${p =>
      (p.desktop ? p.desktop.y : 0) + (p.desktop ? p.desktop.h : 0)};
  }
`

export const Cube = styled.div<CubeProps>`
  ${mixin};
  position: relative;
  z-index: ${p => p.z || 0};

  background-color: ${getBg};
`
export const Circle = styled.div<CubeProps>`
  ${mixin};
  position: relative;
  z-index: ${p => p.z || 0};

  background-color: ${getBg};
  transform: scale(0.8);
  border-radius: 100%;
`
export const SuperCube = styled(Icon)<CubeProps>`
  ${mixin};
  position: relative;
  z-index: ${p => p.z || 0};

  width: 50px;
  height: 50px;
  margin: -5px;
  padding: 18px;
  background-color: ${getBg};
`
export const CubeText = styled(Text).attrs({ as: "span", bg: "transparent" })<
  CubeProps
>`
  ${mixin};
  position: relative;
  background-color: ${getBg};
  overflow: hidden;
`
export const CubeLink = styled(Link)<CubeProps>`
  ${mixin};
  position: relative;
  overflow: hidden;
`
