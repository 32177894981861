import * as React from "react"
// import styled from "styled-components"

const Faces: React.FC<{}> = props => (
  <svg width="406px" height="617px" viewBox="0 0 406 617" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-90.000000, -2162.000000)">
        <g transform="translate(90.000000, 2152.000000)">
          <g transform="translate(0.000000, 10.000000)">
            <rect fill="#B1F4C4" x="0" y="106" width="96" height="96"></rect>
            <rect fill="#B1F4C4" x="103" y="418" width="96" height="96"></rect>
            <rect fill="#B1F4C4" x="207" y="209" width="96" height="96"></rect>
            <rect fill="#FF88A4" x="310" y="104" width="96" height="96"></rect>
            <rect fill="#FF88A4" x="0" y="314" width="96" height="96"></rect>
            <rect fill="#FF88A4" x="103" y="210" width="96" height="96"></rect>
            <rect fill="#FF88A4" x="207" y="417" width="96" height="96"></rect>
            <rect fill="#B1F4C4" x="310" y="312" width="96" height="96"></rect>
            <rect fill="#89E6FF" x="0" y="209" width="96" height="96"></rect>
            <rect fill="#89E6FF" x="103" y="521" width="96" height="96"></rect>
            <rect fill="#89E6FF" x="207" y="313" width="96" height="96"></rect>
            <rect fill="#F3E1FF" x="310" y="208" width="96" height="96"></rect>
            <rect fill="#F3E1FF" x="0" y="417" width="96" height="96"></rect>
            <rect fill="#F3E1FF" x="103" y="313" width="96" height="96"></rect>
            <rect fill="#F3E1FF" x="207" y="105" width="96" height="96"></rect>
            <rect fill="#89E6FF" x="310" y="0" width="96" height="96"></rect>
            <path
              d="M47,259.307692 C58.5979797,259.307692 68,269.019669 68,281 L68,281 L26,281 L26.0028134,280.641278 C26.18826,268.826401 35.5180001,259.307692 47,259.307692 Z M47.2234043,233 C53.2691597,233 58.1702128,238.062626 58.1702128,244.307692 C58.1702128,250.552758 53.2691597,255.615385 47.2234043,255.615385 C41.1776489,255.615385 36.2765957,250.552758 36.2765957,244.307692 C36.2765957,238.062626 41.1776489,233 47.2234043,233 Z"
              fill="#3636FF"
              fillRule="nonzero"
            ></path>
            <path
              d="M149,570.307692 C160.59798,570.307692 170,580.019669 170,592 L170,592 L128,592 L128.002813,591.641278 C128.18826,579.826401 137.518,570.307692 149,570.307692 Z M149.223404,544 C155.26916,544 160.170213,549.062626 160.170213,555.307692 C160.170213,561.552758 155.26916,566.615385 149.223404,566.615385 C143.177649,566.615385 138.276596,561.552758 138.276596,555.307692 C138.276596,549.062626 143.177649,544 149.223404,544 Z"
              fill="#3636FF"
              fillRule="nonzero"
            ></path>
            <path
              d="M252,362.307692 C263.59798,362.307692 273,372.019669 273,384 L273,384 L231,384 L231.002813,383.641278 C231.18826,371.826401 240.518,362.307692 252,362.307692 Z M252.223404,336 C258.26916,336 263.170213,341.062626 263.170213,347.307692 C263.170213,353.552758 258.26916,358.615385 252.223404,358.615385 C246.177649,358.615385 241.276596,353.552758 241.276596,347.307692 C241.276596,341.062626 246.177649,336 252.223404,336 Z"
              fill="#3636FF"
              fillRule="nonzero"
            ></path>
            <path
              d="M356,257.307692 C367.59798,257.307692 377,267.019669 377,279 L377,279 L335,279 L335.002813,278.641278 C335.18826,266.826401 344.518,257.307692 356,257.307692 Z M356.223404,231 C362.26916,231 367.170213,236.062626 367.170213,242.307692 C367.170213,248.552758 362.26916,253.615385 356.223404,253.615385 C350.177649,253.615385 345.276596,248.552758 345.276596,242.307692 C345.276596,236.062626 350.177649,231 356.223404,231 Z"
              fill="#5C3CAF"
              fillRule="nonzero"
            ></path>
            <path
              d="M48,156.307692 C59.5979797,156.307692 69,166.019669 69,178 L69,178 L27,178 L27.0028134,177.641278 C27.18826,165.826401 36.5180001,156.307692 48,156.307692 Z M48.2234043,130 C54.2691597,130 59.1702128,135.062626 59.1702128,141.307692 C59.1702128,147.552758 54.2691597,152.615385 48.2234043,152.615385 C42.1776489,152.615385 37.2765957,147.552758 37.2765957,141.307692 C37.2765957,135.062626 42.1776489,130 48.2234043,130 Z"
              fill="#00BA74"
              fillRule="nonzero"
            ></path>
            <path
              d="M150,467.307692 C161.59798,467.307692 171,477.019669 171,489 L171,489 L129,489 L129.002813,488.641278 C129.18826,476.826401 138.518,467.307692 150,467.307692 Z M150.223404,441 C156.26916,441 161.170213,446.062626 161.170213,452.307692 C161.170213,458.552758 156.26916,463.615385 150.223404,463.615385 C144.177649,463.615385 139.276596,458.552758 139.276596,452.307692 C139.276596,446.062626 144.177649,441 150.223404,441 Z"
              fill="#00BA74"
              fillRule="nonzero"
            ></path>
            <path
              d="M253,259.307692 C264.59798,259.307692 274,269.019669 274,281 L274,281 L232,281 L232.002813,280.641278 C232.18826,268.826401 241.518,259.307692 253,259.307692 Z M253.223404,233 C259.26916,233 264.170213,238.062626 264.170213,244.307692 C264.170213,250.552758 259.26916,255.615385 253.223404,255.615385 C247.177649,255.615385 242.276596,250.552758 242.276596,244.307692 C242.276596,238.062626 247.177649,233 253.223404,233 Z"
              fill="#00BA74"
              fillRule="nonzero"
            ></path>
            <path
              d="M357,154.307692 C368.59798,154.307692 378,164.019669 378,176 L378,176 L336,176 L336.002813,175.641278 C336.18826,163.826401 345.518,154.307692 357,154.307692 Z M357.223404,128 C363.26916,128 368.170213,133.062626 368.170213,139.307692 C368.170213,145.552758 363.26916,150.615385 357.223404,150.615385 C351.177649,150.615385 346.276596,145.552758 346.276596,139.307692 C346.276596,133.062626 351.177649,128 357.223404,128 Z"
              fill="#FF0042"
              fillRule="nonzero"
            ></path>
            <path
              d="M47,466.307692 C58.4819999,466.307692 67.81174,475.826401 67.9971866,487.641278 L68,488 L26,488 C26,476.019669 35.4020203,466.307692 47,466.307692 Z M47.2234043,440 C53.2691597,440 58.1702128,445.062626 58.1702128,451.307692 C58.1702128,457.552758 53.2691597,462.615385 47.2234043,462.615385 C41.1776489,462.615385 36.2765957,457.552758 36.2765957,451.307692 C36.2765957,445.062626 41.1776489,440 47.2234043,440 Z"
              fill="#5C3CAF"
              fillRule="nonzero"
            ></path>
            <path
              d="M149,363.307692 C160.482,363.307692 169.81174,372.826401 169.997187,384.641278 L170,385 L128,385 C128,373.019669 137.40202,363.307692 149,363.307692 Z M149.223404,337 C155.26916,337 160.170213,342.062626 160.170213,348.307692 C160.170213,354.552758 155.26916,359.615385 149.223404,359.615385 C143.177649,359.615385 138.276596,354.552758 138.276596,348.307692 C138.276596,342.062626 143.177649,337 149.223404,337 Z"
              fill="#5C3CAF"
              fillRule="nonzero"
            ></path>
            <path
              d="M252,156.307692 C263.482,156.307692 272.81174,165.826401 272.997187,177.641278 L273,178 L231,178 C231,166.019669 240.40202,156.307692 252,156.307692 Z M252.223404,130 C258.26916,130 263.170213,135.062626 263.170213,141.307692 C263.170213,147.552758 258.26916,152.615385 252.223404,152.615385 C246.177649,152.615385 241.276596,147.552758 241.276596,141.307692 C241.276596,135.062626 246.177649,130 252.223404,130 Z"
              fill="#5C3CAF"
              fillRule="nonzero"
            ></path>
            <path
              d="M356,51.3076923 C367.482,51.3076923 376.81174,60.8264009 376.997187,72.641278 L377,73 L335,73 C335,61.0196693 344.40202,51.3076923 356,51.3076923 Z M356.223404,25 C362.26916,25 367.170213,30.0626263 367.170213,36.3076923 C367.170213,42.5527583 362.26916,47.6153846 356.223404,47.6153846 C350.177649,47.6153846 345.276596,42.5527583 345.276596,36.3076923 C345.276596,30.0626263 350.177649,25 356.223404,25 Z"
              fill="#3636FF"
              fillRule="nonzero"
            ></path>
            <path
              d="M48,363.307692 C59.5979797,363.307692 69,373.019669 69,385 L69,385 L27,385 L27.0028134,384.641278 C27.18826,372.826401 36.5180001,363.307692 48,363.307692 Z M48.2234043,337 C54.2691597,337 59.1702128,342.062626 59.1702128,348.307692 C59.1702128,354.552758 54.2691597,359.615385 48.2234043,359.615385 C42.1776489,359.615385 37.2765957,354.552758 37.2765957,348.307692 C37.2765957,342.062626 42.1776489,337 48.2234043,337 Z"
              fill="#FF0042"
              fillRule="nonzero"
            ></path>
            <path
              d="M150,260.307692 C161.59798,260.307692 171,270.019669 171,282 L171,282 L129,282 L129.002813,281.641278 C129.18826,269.826401 138.518,260.307692 150,260.307692 Z M150.223404,234 C156.26916,234 161.170213,239.062626 161.170213,245.307692 C161.170213,251.552758 156.26916,256.615385 150.223404,256.615385 C144.177649,256.615385 139.276596,251.552758 139.276596,245.307692 C139.276596,239.062626 144.177649,234 150.223404,234 Z"
              fill="#FF0042"
              fillRule="nonzero"
            ></path>
            <path
              d="M253,466.307692 C264.59798,466.307692 274,476.019669 274,488 L274,488 L232,488 L232.002813,487.641278 C232.18826,475.826401 241.518,466.307692 253,466.307692 Z M253.223404,440 C259.26916,440 264.170213,445.062626 264.170213,451.307692 C264.170213,457.552758 259.26916,462.615385 253.223404,462.615385 C247.177649,462.615385 242.276596,457.552758 242.276596,451.307692 C242.276596,445.062626 247.177649,440 253.223404,440 Z"
              fill="#FF0042"
              fillRule="nonzero"
            ></path>
            <path
              d="M357,361.307692 C368.59798,361.307692 378,371.019669 378,383 L378,383 L336,383 L336.002813,382.641278 C336.18826,370.826401 345.518,361.307692 357,361.307692 Z M357.223404,335 C363.26916,335 368.170213,340.062626 368.170213,346.307692 C368.170213,352.552758 363.26916,357.615385 357.223404,357.615385 C351.177649,357.615385 346.276596,352.552758 346.276596,346.307692 C346.276596,340.062626 351.177649,335 357.223404,335 Z"
              fill="#00BA74"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Faces
