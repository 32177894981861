import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Anchor from "../../components/anchor"
import Text from "../../components/text"
import { color } from "../../theme"
import {
  Cube,
  SuperCube,
  SuperCubeProps,
  CubeProps,
  Circle,
} from "../../components/whatweoffer/grid"

export type RecurringProps = {
  title: string
  subtitle1: string
  copy1: string
  subtitle2: string
  copy2: string
}

type Props = {
  data: RecurringProps
}

const Wrapper = styled.div`
  position: relative;
  display: grid;
  background-color: ${color("yellow")};
  min-height: 480px;
`

const LimitWiderPart = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding: 4rem 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
    grid-template-rows: min-content min-content;
  }
`

const RecurringContent = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr 400px;
  }
`

const RecurringGrid = styled.div`
  position: relative;
  display: grid;
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  grid-template-columns: repeat(10, calc(100vw / 10));
  grid-template-rows: repeat(10, calc(100vw / 10));
  ${up("tablet")} {
    grid-template-columns: repeat(20, calc(100vw / 20));
    grid-template-rows: repeat(9, calc(100vw / 20));
  }
  ${up("desktop")} {
    position: absolute;
    top: 0;
    right: 0;
    grid-template-columns: repeat(10, 40px);
    grid-template-rows: repeat(12, 40px);
  }
`

const pipe3: CubeProps[] = [
  {
    desktop: { x: 5, y: 1, w: 1, h: 8 },
    tablet: { x: 16, y: 1, w: 1, h: 8 },
    mobile: { x: 6, y: 1, w: 1, h: 8 },
    bg: "darkgreen",
  },
  {
    desktop: { x: 1, y: 9, w: 5, h: 1 },
    tablet: { x: 12, y: 9, w: 5, h: 1 },
    mobile: { x: 2, y: 9, w: 5, h: 1 },
    bg: "darkgreen",
    bottomright: true,
    bottomleft: true,
  },
  {
    desktop: { x: 1, y: 5, w: 1, h: 4 },
    tablet: { x: 12, y: 5, w: 1, h: 4 },
    mobile: { x: 2, y: 5, w: 1, h: 4 },
    bg: "darkgreen",
    topleft: true,
  },
  {
    desktop: { x: 2, y: 5, w: 9, h: 1 },
    tablet: { x: 13, y: 5, w: 9, h: 1 },
    mobile: { x: 3, y: 5, w: 9, h: 1 },
    bg: "darkgreen",
  },
]

const superPipe: SuperCubeProps[] = [
  {
    desktop: { x: 5, y: 5, w: 1, h: 1 },
    tablet: { x: 16, y: 5, w: 1, h: 1 },
    mobile: { x: 6, y: 5, w: 1, h: 1 },
    bg: "blue",
    z: 1,
    name: "window",
    color: "white",
  },
]

const circlePipe: CubeProps[] = [
  {
    desktop: { x: 5, y: 2, w: 1, h: 1 },
    tablet: { x: 16, y: 2, w: 1, h: 1 },
    mobile: { x: 6, y: 2, w: 1, h: 1 },
    bg: "yellow",
    z: 1,
  },
  {
    desktop: { x: 2, y: 9, w: 1, h: 1 },
    tablet: { x: 13, y: 9, w: 1, h: 1 },
    mobile: { x: 3, y: 9, w: 1, h: 1 },
    bg: "yellow",
    z: 1,
  },
]

const Recurring: React.FC<Props> = ({
  data: { title, subtitle1, copy1, subtitle2, copy2 },
}) => {
  return (
    <Wrapper>
      <RecurringGrid>
        {superPipe.map((pipe, i) => (
          <SuperCube key={`super_cube3_${i}`} {...pipe} />
        ))}
        {circlePipe.map((pipe, i) => (
          <Circle key={`circle3_${i}`} {...pipe} />
        ))}
        {pipe3.map((pipe, i) => (
          <Cube key={`cube3_${i}`} {...pipe} />
        ))}
      </RecurringGrid>
      <LimitWiderPart>
        <Anchor id="recurring" top="-80px" />
        <Text type="h4">{title}</Text>
        <RecurringContent>
          <div>
            <Text color="blue">{subtitle1}</Text>
            <Text>{copy1}</Text>
          </div>
          <div>
            <Text color="blue">{subtitle2}</Text>
            <Text>{copy2}</Text>
          </div>
        </RecurringContent>
        <div />
      </LimitWiderPart>
    </Wrapper>
  )
}

export default Recurring
