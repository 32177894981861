import * as React from "react"
import styled from "styled-components"

import Layout from "../../components/layout"

import Intro, { IntroProps } from "./intro"
import Flexible, { FlexibleProps } from "./flexible"
import Recurring, { RecurringProps } from "./recurring"
import Escrow, { EscrowProps } from "./escrow"
import BankingLite, { BankingProps } from "./bankinglite"
import Reward, { RewardProps } from "./reward"
import Console, { ConsoleProps } from "./console"

import TextWithImage, {
  TextProps as TextWithImageProps,
} from "../TextWithImage"

export type Props = {
  data: {
    intro: IntroProps
    flexible: FlexibleProps
    recurring: RecurringProps
    escrow: EscrowProps
    payout: TextWithImageProps
    banking: BankingProps
    reward: RewardProps
    funds: TextWithImageProps
    duedilligence: TextWithImageProps
    risk: TextWithImageProps
    dynamic: TextWithImageProps
    console: ConsoleProps
  }
  options?: {}
}

const Container = styled.div`
  display: grid;
  margin-top: 80px;
`

const WhatWeOffer: React.FC<Props> = ({ data, options }) => {
  return (
    <Layout>
      <Container>
        <Intro data={data.intro} />
        <Flexible data={data.flexible} />
        <Recurring data={data.recurring} />
        <Escrow data={data.escrow} />
        <TextWithImage
          data={data.payout}
          options={{
            isTextRight: true,
            titleColor: "rose",
            anchor: { id: "instant-payout", top: "-95px" },
          }}
        />
        <BankingLite data={data.banking} />
        <Reward data={data.reward} />
        <TextWithImage
          data={data.funds}
          options={{
            isTextRight: true,
            backgroundColor: "white",
            titleColor: "black",
            padding: "4rem",
            anchor: { id: "regulatory", top: "-95px" },
          }}
        />
        <TextWithImage
          data={data.duedilligence}
          options={{
            backgroundColor: "blue",
            titleColor: "white",
            textColor: "white",
            fit: "contain",
            padding: "4rem",
          }}
        />
        <TextWithImage
          data={data.risk}
          options={{
            isTextRight: true,
            backgroundColor: "lightblue",
            titleColor: "black",
            fit: "contain",
            padding: "4rem",
          }}
        />
        <TextWithImage
          data={data.dynamic}
          options={{
            isTextRight: true,
            titleColor: "rose",
            anchor: { id: "dynamic", top: "-95px" },
          }}
        />
        <Console data={data.console} />
      </Container>
    </Layout>
  )
}

export default WhatWeOffer
