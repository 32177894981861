import * as React from "react"
import styled from "styled-components"

import {
  Cube,
  SuperCube,
  CubeText,
  SuperCubeProps,
  Circle,
  CubeProps,
} from "../../../components/whatweoffer/grid"
import Text from "../../../components/text"
import { Link } from "../../../components/link"
import Anchor from "../../../components/anchor"
import { IntroProps } from "."

const pipe1: CubeProps[] = [
  { mobile: { x: 1, y: 6, w: 1, h: 1 }, bg: "lightblue" },
  { mobile: { x: 2, y: 6, w: 4, h: 1 }, bg: "lightblue", topright: true },
  { mobile: { x: 5, y: 7, w: 1, h: 3 }, bg: "lightblue" },
  { mobile: { x: 5, y: 10, w: 4, h: 1 }, bg: "lightblue", bottomleft: true },
  { mobile: { x: 9, y: 11, w: 1, h: -10 }, bg: "lightblue", bottomright: true },
]

const pipeCircle: CubeProps[] = [
  { mobile: { x: 15, y: 13, w: 1, h: 1 }, bg: "yellow", z: 1 },
]

const pipeSuper: SuperCubeProps[] = [
  {
    mobile: { x: 5, y: 6, w: 1, h: 1 },
    bg: "red",
    z: 1,
    name: "expand-window",
    color: "white",
  },
  {
    mobile: { x: 5, y: 10, w: 1, h: 1 },
    bg: "blue",
    z: 1,
    name: "card",
    color: "white",
  },
  {
    mobile: { x: 9, y: 10, w: 1, h: 1 },
    bg: "darkgreen",
    z: 1,
    name: "sync",
    color: "white",
  },
  {
    mobile: { x: 9, y: 6, w: 1, h: 1 },
    bg: "purple",
    z: 1,
    name: "expand",
    color: "white",
  },
  {
    mobile: { x: 15, y: 6, w: 1, h: 1 },
    bg: "darkgreen",
    z: 1,
    name: "bank",
    color: "white",
  },
  {
    mobile: { x: 19, y: 6, w: 1, h: 1 },
    bg: "purple",
    z: 1,
    name: "star",
    color: "white",
  },
  {
    mobile: { x: 15, y: 10, w: 1, h: 1 },
    bg: "red",
    z: 1,
    name: "escrow",
    color: "white",
  },
  {
    mobile: { x: 19, y: 10, w: 1, h: 1 },
    bg: "blue",
    z: 1,
    name: "window",
    color: "white",
  },
]
const pipe2: CubeProps[] = [
  { mobile: { x: 19, y: 10, w: 5, h: 1 }, bg: "lightblue", bottomleft: true },
  { mobile: { x: 19, y: 6, w: 1, h: 4 }, bg: "lightblue", topright: true },
  { mobile: { x: 15, y: 6, w: 4, h: 1 }, bg: "lightblue", topleft: true },
  { mobile: { x: 15, y: 7, w: 1, h: 11 }, bg: "lightblue" },
  { mobile: { x: 19, y: 10, w: 1, h: 1 }, bg: "lightblue" },
]

const SGrid = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr repeat(10, 40px) 480px repeat(10, 40px) 1fr;
  grid-template-rows: repeat(16, 40px) auto;
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  background-color: transparent;
`
const CustomGrid = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr repeat(32, 40px) 1fr;
  grid-template-rows: repeat(7, 40px) auto repeat(7, 40px) auto;
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  background-color: transparent;
`
const PinkGrid = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr repeat(32, 40px) 1fr;
  grid-template-rows: repeat(8, 40px) auto 40px 40px;
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  background-color: transparent;
`
const FirstLine = styled.div`
  position: relative;
  grid-column: 3 / 19;
  grid-row: 2 / 9;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin: 2rem 0;
`
const SecondLine = styled.div`
  position: relative;
  grid-column: 8 / 33;
  grid-row: 11 / 17;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin: 2rem 0;
  margin-bottom: 4rem;
`

const TextBlock = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto 1fr;
  justify-items: flex-start;
  align-items: flex-start;
`

const Intro: React.FC<{ data: IntroProps }> = ({
  data: {
    title1,
    copy1,
    title2,
    subtitle21,
    copy21,
    subtitle22,
    copy22,
    subtitle23,
    copy23,
    subtitle24,
    copy24,
    subtitle25,
    copy25,
    subtitle26,
    copy26,
    link26,
    title3,
    subtitle31,
    copy31,
    subtitle32,
    copy32,
  },
}) => {
  return (
    <>
      <SGrid>
        {pipeCircle.map((pipe, i) => (
          <Circle key={`circle_${i}`} {...pipe} />
        ))}
        {pipeSuper.map((pipe, i) => (
          <SuperCube key={`super_cube1_${i}`} {...pipe} />
        ))}
        {pipe1.map((pipe, i) => (
          <Cube key={`cube1_${i}`} {...pipe} />
        ))}
        {pipe2.map((pipe, i) => (
          <Cube key={`cube2_${i}`} {...pipe} />
        ))}
        <div
          style={{
            gridRow: "3/18",
            gridColumn: "12",
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "grid",
              gridGap: "2rem",
              paddingBottom: `${1.75 * 40}px`,
            }}
          >
            <Text type="headline" align="center">
              {title1}
            </Text>
            <Text align="center" color="grey">
              {copy1}
            </Text>
          </div>
        </div>
      </SGrid>
      <CustomGrid>
        <Cube mobile={{ x: 21, y: 1, w: 10, h: 1 }} bg="lightblue" />
        <Cube
          mobile={{ x: 31, y: 1, w: 1, h: 6 }}
          bg="lightblue"
          topright
          bottomleft
        />
        <Cube mobile={{ x: 32, y: 6, w: 3, h: 1 }} bg="lightblue" />
        <Cube mobile={{ x: 20, y: 1, w: 1, h: 8 }} bg="lightblue" topleft />
        <Cube
          mobile={{ x: 6, y: 9, w: 15, h: 1 }}
          bg="lightblue"
          topleft
          bottomright
        />
        <Cube mobile={{ x: 6, y: 10, w: 1, h: 7 }} bg="lightblue" />
        <Circle mobile={{ x: 14, y: 9, w: 1, h: 1 }} bg="yellow" />
        <FirstLine>
          <CubeText mobile={{ x: 1, y: 1, w: 3, h: 1 }} type="h4">
            <Anchor id={"flexibility"} top="-120px" />
            {title2}
          </CubeText>
          <TextBlock style={{ gridRow: "2", gridColumn: "1" }}>
            <Text color="blue">{subtitle21}</Text>
            <Text>{copy21}</Text>
          </TextBlock>
          <TextBlock style={{ gridRow: "2", gridColumn: "2" }}>
            <Text color="darkgreen">{subtitle22}</Text>
            <Text>{copy22}</Text>
          </TextBlock>
        </FirstLine>
        <SecondLine>
          <TextBlock style={{ gridRow: "1/3", gridColumn: "1" }}>
            <Text color="darkgreen">{subtitle23}</Text>
            <Text>{copy23}</Text>
          </TextBlock>
          <TextBlock style={{ gridRow: "3/4", gridColumn: "1" }}>
            <Text color="blue">{subtitle24}</Text>
            <Text>{copy24}</Text>
          </TextBlock>
          <TextBlock style={{ gridRow: "1/4", gridColumn: "2" }}>
            <Text color="red">{subtitle25}</Text>
            <Text>{copy25}</Text>
          </TextBlock>
          <TextBlock style={{ gridRow: "1/4", gridColumn: "3" }}>
            <Text color="darkgreen">{subtitle26}</Text>
            <Text>{copy26}</Text>
            <Link to={link26.to}>{link26.copy}</Link>
          </TextBlock>
        </SecondLine>
      </CustomGrid>
      <PinkGrid>
        <Cube mobile={{ x: 1, y: 1, w: 34, h: 12 }} bg="rose" />
        <Cube mobile={{ x: 6, y: 1, w: 1, h: 4 }} bg="lightblue" />
        <Cube mobile={{ x: 3, y: 5, w: 7, h: 1 }} bg="blue" topright topleft />
        <Cube mobile={{ x: 3, y: 6, w: 1, h: 6 }} bg="blue" />
        <Cube mobile={{ x: 9, y: 6, w: 1, h: 6 }} bg="blue" />
        <Circle mobile={{ x: 3, y: 7, w: 1, h: 1 }} bg="yellow" />
        <Circle mobile={{ x: 9, y: 7, w: 1, h: 1 }} bg="yellow" />
        <CubeText mobile={{ x: 14, y: 3, w: 10, h: 1 }} type="h4">
          <Anchor id={"split"} top="-120px" />
          {title3}
        </CubeText>
        <CubeText mobile={{ x: 14, y: 5, w: 9, h: 1 }} color="blue">
          {subtitle31}
        </CubeText>
        <CubeText mobile={{ x: 14, y: 6, w: 9, h: 5 }}>{copy31}</CubeText>
        <CubeText mobile={{ x: 25, y: 5, w: 8, h: 1 }} color="blue">
          {subtitle32}
        </CubeText>
        <CubeText mobile={{ x: 25, y: 6, w: 8, h: 5 }}>{copy32}</CubeText>
      </PinkGrid>
    </>
  )
}

export default Intro
