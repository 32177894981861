import React from "react"
import { Helmet } from "react-helmet"

import WhatWeOffer from "../blocks/WhatWeOffer"

const WhatWeOfferPage = () => (
  <>
    <Helmet>
      <title>Paybase - What we offer</title>
      <meta property="og:title" content="Paybase - What we offer" />
    </Helmet>
    <WhatWeOffer
      data={{
        intro: {
          title1: "What we offer",
          copy1:
            "The Paybase solution allows you to build your product with complete flexibility. We streamline payments, compliance and risk management into a single API, giving you access to state-of-the-art capabilities and allowing you to build more disruptive products.",
          title2: "Flexible Payments Routing_",
          subtitle21: "Complex routing",
          copy21:
            "Platform businesses need to route payments between multiple parties whilst instantly receiving their own commission. Paybase automates this process, providing limitless ways in which payments can be routed.",
          subtitle22: "Event-Driven Transactions",
          copy22:
            "Create triggers that initiate a transaction as a predefined event occurs. An example of this may be an automated withdrawal being initiated as soon as a user account reaches £1000.",
          subtitle23: "Third-party Payouts",
          copy23:
            "Make instant payouts to third parties that are involved in your supply chain/business model.",
          subtitle24: "Real-time Bank Transfers",
          copy24:
            "Allow your users to pay-in to your platform via bank transfer as well as card payments. This can be done in real-time with Faster Payments.",
          subtitle25: "Instant Payouts",
          copy25:
            "Never make your users wait for payment. Unlike most providers, the Paybase solution enables you to pay out as soon as a job is finished. This is a great feature to encourage user loyalty, to reduce lead leakage and to stand out in a saturated market.",
          subtitle26: "Tailored By You",
          copy26: `We are built with flexibility at our core, allowing us to support a wide variety of payment flows. Speak to us today about what your business needs.`,
          link26: { copy: "Get in touch", to: "https://trustshare.co" },
          title3: "Split payments_",
          subtitle31: "Fee Extraction",
          copy31:
            "Collect commission fees from each transaction automatically. Fees can vary based on transaction size, seller, date and more.",
          subtitle32: "Multi-party Payments",
          copy32:
            "Split incoming transactions between multiple beneficiaries such as freelancers working for an agency or gig workers working on a large project. Automatically customise how much commission is deducted from workers on the same project. Whatever the split, we’ll automate it.",
        },
        flexible: {
          title: "Flexible Accounts_",
          list: [
            "Seller accounts",
            "Supplier accounts",
            "Freelancer accounts",
            "Affiliate accounts",
          ],
          copy:
            "Accounts can be created for sellers, freelancers, affiliates, suppliers and any custom roles that you need to fit your business model. Our solution is completely white-labelled meaning that these accounts can be accessed immediately when logging onto your platform, allowing users to transfer money instantly, securely and at a time that suits them.",
          block: {
            title: "Can’t see what you need?",
            copy:
              "This list is in no way exhaustive. Paybase is able to create custom ‘Roles’ for your platform-specific accounts which have varying capabilities and restrictions based on your business needs.",
            link: { copy: "Get in touch", to: "https://trustshare.co" },
          },
        },
        recurring: {
          title: "Recurring payments_",
          subtitle1: "Subscription Services",
          copy1:
            "Create recurring card payments for subscription services. An example of this may be a marketplace for gym classes where users can pay for a weekly class.",
          subtitle2: "Scheduled Payments",
          copy2:
            "Allow payments/transfers to be scheduled for a certain time. An example of this may be an online marketplace that wishes to automatically withdraw its balance to a bank account at a particular date and time each week/month.",
        },
        escrow: {
          title: "Escrow_",
          animation: "/animations/homepage/escrow.json",
          list: [
            "Hold, divide and return payments between buyers and sellers",
            "Build trust in your brand with a white-label solution",
            "Increase user retention with security, reassurance and peace of mind",
          ],
          copy:
            "The Paybase escrow solution is built to allow maximum flexibility, catering to a vast array of use cases. If a marketplace item is received >1 week late, for instance, you may wish to send 75% of the funds to the seller and return the remaining 25% to the buyer. From simply holding funds to facilitating one-to-many payment flows and more complex functionalities, we enable you to build the product you want - without compromise.",
          link: { to: "https://trustshare.co", copy: "Read more" },
        },
        payout: {
          title: "Instant End-To-End Payments_",
          content:
            "Never make your users wait for payment. Unlike most providers that force gig workers to wait up to 10 days for each payment to clear, the Paybase solution enables you to pay out as soon as a gig has been completed. Our market-leading solution therefore enables gig workers to spend their earnings immediately - this encourages loyalty to your platform, reduces lead leakage and assures that you stand out from your competitors.",
          image: {
            src: "/who-is-it-for/gig-economy/img2.svg",
            alt: "placeholder alt",
          },
        },
        banking: {
          title: "Banking Lite_",
          subtitle: "FinTech Product",
          copy:
            "Create account numbers and sort codes to facilitate banking lite functionality. Allow platform users to put funds into a user account which can then be stored, transferred A2A and/or paid out to third parties. An example of this might be a P2P payments app or an investment service.",
          image: { alt: "banking lite", src: "/what-we-offer/banklite.svg" },
        },
        reward: {
          title: "Promotions, Loyalty & Rewards_",
          block1: {
            title: "Cashback",
            copy:
              "Offer your users cashback on purchases as a form of buyer retention. This could be in the form of credit to spend on the platform or fiat currency that can be withdrawn.",
            image: { src: "/what-we-offer/cyclearrow.svg", alt: "cyclearrow" },
          },
          block2: {
            title: "Purchase Subsidies",
            copy:
              "Offer automatic subsidies on purchases dependent on a number of factors such as number of previous purchases or date of last purchase. An example of this may be a discount applied to a buyerʼs first purchase or a discount offered to a user that has not made a purchase for X amount of time to increase engagement.",
            image: { src: "/what-we-offer/coins.svg", alt: "coins" },
          },
          block3: {
            title: "Referrals",
            copy:
              "Subsidise your usersʼ transactions for referring others to your platform. An example of this may be offering a 20% discount on the next 3 marketplace purchases for both referrer and referee.",
            image: { src: "/what-we-offer/people.svg", alt: "people" },
          },
        },
        funds: {
          title: "Funds Custody & Regulatory Cover_",
          content:
            "Paybase is an FCA-regulated financial institution. Our Electronic Money Licence authorises us to hold our clients’ and their users’ fiat funds safely in a safeguarded client account. We’ll cover your regulatory needs, relieving you from having to manoeuvre compliance hurdles and giving you the space to focus on your all-important product innovation.",
          image: { src: "/what-we-offer/fda.svg", alt: "coins" },
        },
        duedilligence: {
          title: "Due Diligence_",
          content:
            "We take a UX-driven approach to Due Diligence. Every business is different, and we treat them as such, only requesting a level of user information that matches transaction volume. This streamlines the process for you and your users and ensures that the level of Due Diligence carried out is relevant to your business’s requirements.",
          links: [{ to: "/how-we-do-it", copy: "Read more" }],
          animation: "/animations/engines/onboarding-engine.json",
        },
        risk: {
          title: "Risk_",
          content:
            "Our state-of-the-art Risk Engine allows you to monitor and manage financial risk in real-time, evaluating all activity on your platform to instantly act on it. We work with you to set up a framework that’s right for your business, making financial crime prevention stringent but seamless.",
          links: [{ to: "/how-we-do-it#riskengine", copy: "Read more" }],
          animation: "/animations/engines/risk-engine-blue.json",
        },
        dynamic: {
          title: "Dynamic Pricing for your Users_",
          content:
            "If, for instance, you are a platform connecting dog walkers with owners, you may wish to offer a tiered fee structure, rewarding your most active users with lower commission fees. This not only incentivises walkers to take on more clients but reduces the likelihood of them switching to a new platform.",
          image: {
            src: "/who-is-it-for/sharing-economy/img3.svg",
            alt: "placeholder alt",
          },
        },
        console: {
          title: "Console_",
          copy:
            "The Console acts as the admin interface of the Paybase solution, allowing you to manage your users, sellers, affiliates, third parties and more. All activity occurring on your platform is displayed in a simple, attractive way, allowing you to keep track of user behaviour and act on any activity that our Risk Engine has flagged as suspicious.",
          list: [
            "Your payments control centre",
            "Manage alerts and disputes from within the Console",
            "Get access to business-enhancing insights",
          ],
          image: { src: "/what-we-offer/console.svg", alt: "console" },
        },
      }}
    />
  </>
)

export default WhatWeOfferPage
